// extracted by mini-css-extract-plugin
export var image = "frameworks-module--image--A8csa";
export var mainContainer = "frameworks-module--mainContainer--IRbCs";
export var postContainer = "frameworks-module--postContainer--yKiRS";
export var postText = "frameworks-module--postText--dceJZ";
export var authorSpan = "frameworks-module--authorSpan--PEZTh";
export var posth1 = "frameworks-module--posth1--kqpLs";
export var postp = "frameworks-module--postp--62rxw";
export var verticalAdContainer = "frameworks-module--verticalAdContainer--xA6Fw";
export var adContainerWide = "frameworks-module--adContainerWide--mRgF-";
export var adContainerSmall = "frameworks-module--adContainerSmall--nXI58";
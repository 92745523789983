import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import { graphql } from "gatsby"

import * as styles from "../styles/frameworks.module.css"

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "databases" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            title
            imageurl
            description
            date
            author
            imagealt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const DatabasesPage = props => {
  console.log(props.data.allMarkdownRemark.edges.length)

  return (
    <Layout>
      <Helmet>
        <title>Webdevolution | Database articles</title>
        <meta
          name="description"
          content="Back-end web development blog posts."
        />
      </Helmet>
      {props.data.allMarkdownRemark.edges.length === 0 ? (
        <div>No posts in this caterory yet!</div>
      ) : (
        <div className={styles.mainContainer}>
          {props.data.allMarkdownRemark.edges.map(i => {
            return (
              <div
                key={i.node.frontmatter.title}
                className={styles.postContainer}
              >
                <img
                  className={styles.image}
                  src={i.node.frontmatter.imageurl}
                  alt={i.node.frontmatter.imagealt}
                />

                <div className={styles.postText}>
                  <h1 className={styles.posth1}>{i.node.frontmatter.title}</h1>

                  <p className={styles.postp}>
                    {i.node.frontmatter.description}
                  </p>
                  <p className={styles.authorSpan}>
                    {i.node.frontmatter.date} By{" "}
                    <span className={styles.authorSpan}>
                      {i.node.frontmatter.author}
                    </span>
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Layout>
  )
}
export default DatabasesPage
